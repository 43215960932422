import unknownToString from '@lib/types/unknownToString'
import isArray from 'lodash/isArray'
import { format } from 'util'
import { consoleLogger } from '.'
import debugLogger from './debugLogger'
import { formatLogMessage, LogTags } from './formatLogMessage'
import type { ILogger } from './ILogger'

export class ConsoleLogger implements ILogger {
  private logTags: string[] = [LogTags.UNKNOWN]

  constructor(logTags: string[] = [LogTags.UNKNOWN]) {
    this.logTags = logTags
  }

  debug = debugLogger

  /**
   * Logs an error-level message to the console and pushed a log to datadog
   */
  error(message: string | unknown, ...args: any[]) {
    consoleLogger.error(
      formatLogMessage(this.logTags, format(unknownToString(message), ...args)),
    )
  }

  /**
   * Retrieve the current list of tags
   */
  getTags(): string[] {
    return this.logTags
  }

  /**
   * Logs an info-level message to the console and pushed a log to datadog
   */
  info(message: string, ...args: any[]) {
    consoleLogger.info(formatLogMessage(this.logTags, format(message, ...args)))
  }

  /**
   * Logs an error-level message from a caught error to the console but doesn't push to datadog
   */
  logError(error: unknown, message: string, ...args: any[]) {
    consoleLogger.error(
      formatLogMessage(this.logTags, format(unknownToString(message), ...args)),
    )
    if (error instanceof Error) {
      consoleLogger.error(
        'Error message: %o and stack trace: %o',
        error.message,
        error.stack,
      )
    }
  }

  /**
   * Removes tags from the current tag list. Preserves insertion order of original tags.
   */
  pruneTags(lessLogTags: string | string[]): string[] {
    const lessLogTagsArray = isArray(lessLogTags) ? lessLogTags : [lessLogTags]
    const prunedLogTags = this.logTags.filter(
      (logTag) => !lessLogTagsArray.includes(logTag),
    )
    this.logTags = prunedLogTags

    return this.logTags
  }

  /**
   * Appends tags to the current tag list. Preserves insertion order.
   */
  pushTags(moreLogTags: string | string[]): string[] {
    const logTagsSet = new Set(this.logTags)
    const moreLogTagsArray = [moreLogTags].flat()
    moreLogTagsArray.map((logTag) => logTagsSet.add(logTag))

    this.logTags = Array.from(logTagsSet)

    return this.logTags
  }

  /**
   * Clears all existing tags except for the tag list.
   */
  resetTags(originalLogTags: string | string[] = []): string[] {
    const logTagsSet = new Set(originalLogTags)
    this.logTags = Array.from(logTagsSet)

    return this.logTags
  }

  /**
   * Logs an info message only if we are in development
   */
  verboseLog(...args: any[]) {
    if (process.env.NODE_ENV === 'development') {
      consoleLogger.info(format(...args))
    }
  }

  /**
   * Logs a warning-level message to the console and pushed a log to datadog
   */
  warn(message: string, ...args: any[]) {
    consoleLogger.warn(formatLogMessage(this.logTags, format(message, ...args)))
  }
}
