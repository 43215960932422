import { isProcessClientSide } from '@lib/config/runtime'
import pickBy from 'lodash/pickBy'
import uniq from 'lodash/uniq'

export const searchParamFromUrl = (param: SearchParam) => {
  const urlParams = new URLSearchParams(
    isProcessClientSide() ? window.location.search : '',
  )
  return urlParams.get(param)
}

export const serializeSearchParamValues = (values: string[]) => {
  return uniq(values).join(',')
}

export const deserializeSearchParamValues = (
  attribute: SearchParam,
  value: string | null,
) => {
  const params = value?.split(',').filter(Boolean) ?? ([] as string[])

  if (attribute.toLowerCase().includes('exclusion')) {
    // https://discourse.algolia.com/t/facet-filter-negative-and-not-in-react-instant-search/4470
    // It is actually possible to negate filters by using the Algolia API
    // you can add a - (minus sign) before a facet to negate it.
    return params.map((value) => `-${value}`)
  }

  if ((ATTRIBUTES_WITH_ANY as unknown as string[]).includes(attribute)) {
    if (params.length > 0) {
      params.push('any')
    }
  }

  return params
}

export const searchParamsOnWindow = () => {
  // remove undefined + null values on object
  return pickBy(getAllSearchParams())
}

// These attributes have an "any" entry that indicates that any value is acceptable
export const ATTRIBUTES_WITH_ANY = [
  'diseaseStageInclusion',
  'diseaseTNMInclusion',
  'linesOfTherapyInclusion',
  'gradeGleasonScoreInclusion',
  'priorTreatmentsInclusion',
] as const

// These reference to both the query params in the URL and the algolia refinement/facet filter names
export const INCLUSION_CRITERIA_FILTERS = [
  'diseaseStageExclusion',
  'geneticMarkers',
  'linesOfTherapyExclusion',
  'priorTreatmentsExclusion',
  ...ATTRIBUTES_WITH_ANY,
] as const

// These are the other filters in our modals in the old search
export const MULTI_SELECT_FILTERS = [
  'ageList',
  'conditions',
  'ecogScore',
  'interventionTypes',
  'overallStatus',
  'phaseList',
  'stageOfTreatment',
  'stagesOfDisease',
] as const

// We don't use these for filtering refinements
const OTHER_PARAMS = [
  'conditionDisplayName',
  'modalType',
  'page',
  'shareModal',
  'shortcode',
  'topicTag',
  'trialSearch',
] as const

// These are other query params that we use for filtering trials
export const OTHER_FILTERS = [
  'condition',
  'currentLocation',
  'distance',
  'effectivenessScore',
  'hasNoPlacebo',
  'lat',
  'lng',
  'query',
  'safetyScore',
  'treatment',
] as const

export const ALL_SEARCH_PARAMS = [
  ...INCLUSION_CRITERIA_FILTERS,
  ...MULTI_SELECT_FILTERS,
  ...OTHER_PARAMS,
  ...OTHER_FILTERS,
]

export type InclusionCriteriaFilter =
  (typeof INCLUSION_CRITERIA_FILTERS)[number]
export type MultiSelectFilter = (typeof MULTI_SELECT_FILTERS)[number]
export type OtherFilters = (typeof OTHER_FILTERS)[number]
export type OtherParams = (typeof OTHER_PARAMS)[number]

export type SearchParam =
  | InclusionCriteriaFilter
  | OtherFilters
  | MultiSelectFilter
  | OtherParams

export type SearchParams = { [K in SearchParam]: string | null }

export const getAllSearchParams = () => {
  const urlParams = new URLSearchParams(
    isProcessClientSide() ? window.location.search : '',
  )
  const searchParams = {} as SearchParams

  ALL_SEARCH_PARAMS.forEach((filter) => {
    searchParams[filter] = urlParams.get(filter)
  })

  return searchParams
}
