// TODO: https://linear.app/withpower/issue/POW-384/fix-could-not-find-a-declaration-file-for-module-for-segment-analytics
// @ts-ignore
import segmentPlugin from '@analytics/segment'
import type { FilterState } from '@components/app/SiteStaffPortal/RegistryPage/SearchAndFilterBar/FilterModal/types'
import { extractSegmentAnonymousIdFromCookie } from '@lib/browsers/cookies/extractSegmentAnonymousIdFromCookie'
import { isProcessClientSide } from '@lib/config/runtime'
import isNotBot from '@lib/tracking/analytics-plugin-is-not-bot'
import { type ExtendedFormDataPoint } from '@lib/types/FormData'
import getUserIdTokenFromJwtCookie from '@modules/auth/jwt/getUserIdTokenFromJwtCookie'
import Analytics from 'analytics'
import type { NextRouter } from 'next/router'
import { getPageTrackPropsFromRouter } from './getPageTrackPropsFromRouter'
import getSegmentUserIdFromCookie from './getSegmentUserIdFromCookie'

export const analytics = Analytics({
  app: 'withpower',
  debug:
    process.env.NODE_ENV !== 'production' ||
    process.env.CURRENT_ENV === 'preview',
  plugins: [
    isNotBot(),
    segmentPlugin({
      writeKey: process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY ?? '',
    }),
  ],
})

/**
 * Add `window.analytics` for Segment tracking.
 * Add `window.hj` for HotJar event tracking.
 * See https://help.hotjar.com/hc/en-us/articles/4405109971095-Events-API-Reference
 */
declare global {
  interface Window {
    analytics?: typeof Analytics
    hj?: (mustBeEvent: 'event', eventName: string) => void
  }
}

/**
 * Tracking source is used to differentiate between different instances of the same component.
 * For example, we have an "apply to trial" button in the table of contents and in the hero section of the trial page.
 */
export enum TrackingSource {
  APPLY_FLOW_V2 = 'apply_flow_v2',
  ARTICLE = 'article',
  CENTRAL_SITE = 'central_site',
  DIRECTORY = 'directory',
  DOCTOR_DIRECTORY = 'doctor_directory',
  ELIGIBILITY_TACHOMETER_CARD = 'eligibility_tachometer_card',
  FAQ = 'faq',
  FLOATING_MOBILE_CTA = 'floating_mobile_cta',
  GET_NOTIFIED_ABOUT_TRIALS_CARD = 'get_notified_about_trials_card',
  GET_STARTED_FLOW = 'get_started_flow',
  HOMEPAGE = 'homepage',
  INTERLINKING_LIST = 'interlinking_list',
  LISTING = 'listing',
  LISTING_HERO = 'listing_hero',
  LISTING_TABLE_OF_CONTENTS = 'listing_table_of_contents',
  LOWER_PHOTO_CARD = 'lower_photo_card',
  NAVBAR = 'navbar',
  PAID_LANDER_FOOTER = 'paid_lander_footer',
  PAID_LANDER_FEATURE = 'paid_lander_feature',
  PAID_LANDER_HERO = 'paid_lander_hero',
  PARTNER_TRIAL_SEARCH = 'partner-trial-search',
  PARTNER_TRIALS = 'partner_trials',
  PATIENT = 'patient',
  PATIENT_APPLY_PACKAGE = 'patient_apply_package',
  PATIENT_INBOX = 'patient_inbox',
  PATIENT_POST_APPLY = 'patient_post_apply',
  PRIMARY_CTA = 'primary_cta',
  PROFILE = 'profile',
  PROVIDER_LANDER_FEATURE = 'provider_lander_feature',
  PROVIDER_LANDER_FOOTER = 'provider_lander_footer',
  PROVIDER_LANDER_HERO = 'provider_lander_hero',
  REFERRALS_TABLE = 'referrals_table',
  REFERRAL_LANDER = 'referral_lander',
  REFERRALS_TRIAL_APPLICATION_MODAL = 'referrals_trial_application_modal',
  REGISTRY = 'registry',
  RELATED_LINKS_TRIAL = 'related_links_trial',
  RESEARCHER = 'researcher',
  RESEARCHER_LANDER_FEATURE = 'researcher_lander_feature',
  RESEARCHER_LANDER_FOOTER = 'researcher_lander_footer',
  RESEARCHER_LANDER_HERO = 'researcher_lander_hero',
  SEARCH = 'search',
  SEARCH_EXPERIMENT = 'search_experiment',
  SITE_OR_CRO = 'site_or_cro',
  SPONSORS_LANDER_FOOTER = 'sponsors_lander_footer',
  SPONSORS_LANDER_HERO = 'sponsors_lander_hero',
  SPONSORS_LANDER_TOP_TRIALS = 'sponsors_lander_top_trials',
  TREATMENT_PAGE = 'treatment_page',
  TRIAL_APPLICATION_FORM = 'trial_application_form',
  TRIAL_PRO_MATCHES_TABLE = 'trial_pro_matches_table',
  TRIAL_PRO_ONBOARDING = 'trial_pro_onboarding',
  TRIAL_PRO_PATIENT_PROFILE = 'trial_pro_patient_profile',
  TRIAL_PRO_PRE_SCREENER = 'trial_pro_pre_screener',
  TRIAL_PRO_UPCOMING_CTMS_INTEGRATION = 'trial_pro_upcoming_ctms_integration',
  TRIAL_PRO_UPCOMING_REPORTING = 'trial_pro_upcoming_reporting',
  TRIAL_PRO_UPCOMING_SITE_PAGE = 'trial_pro_upcoming_site_page',
  TRIAL_PRO_UPCOMING_TRIAL_SEARCH_WIDGET = 'trial_pro_upcoming_trial_search_widget',
  TRIAL_PRO_SETTINGS = 'trial_pro_settings',
}

/**
 * ATTENTION: Please use naming formula: {trial_pro | null}_{surface_area}_{noun}_{verb_past_tense}
 */

export enum TrackingEvent {
  ADDITIONAL_TRIAL_APPLIES_CHECKBOX_CHECKED = 'Additional Trial Applies Checkbox Checked',
  ADDITIONAL_TRIAL_APPLIES_CHECKBOX_UNCHECKED = 'Additional Trial Applies Checkbox UnChecked',
  ADDITIONAL_TRIAL_APPLIES_SUBMIT_BUTTON_CLICKED = 'Additional Trial Applies Submit Button Clicked',
  ARTICLE_CARD_CLICKED = 'Article Card Clicked',
  APPLY_NOW_CLICKED = 'Apply Now Clicked', // Send - Lead
  APPLY_PACKAGE_ACCORDION_CLOSED = 'Apply Package Accordion Closed',
  APPLY_PACKAGE_ACCORDION_OPENED = 'Apply Package Accordion Opened',
  APPLY_PACKAGE_AVAILABILITY_SEEN = 'Apply Package Availability Seen',
  APPLY_PACKAGE_AVAILABILITY_CLICKED = 'Apply Package Availability Clicked',
  APPLY_PACKAGE_AVAILABILITY_SUBMIT_CLICKED = 'Apply Package Availability Submit Clicked',
  APPLY_PACKAGE_EDUCATION_SECTION_OPENED = 'Apply Package Education Section Opened',
  APPLY_PACKAGE_CALL_SITE_STEP_CALL_COMPLETED = 'Apply Package Call Site Step Call Completed',
  APPLY_PACKAGE_CALL_SITE_STEP_ERRORED = 'Apply Package Call Site Step Errored',
  APPLY_PACKAGE_CALL_SITE_STEP_PATIENT_CALL_INITIATED = 'Apply Package Call Site Step Patient Call Initiated',
  APPLY_PACKAGE_CALL_SITE_STEP_PATIENT_CALL_CONNECTED = 'Apply Package Call Site Step Patient Call Connected',
  APPLY_PACKAGE_CALL_SITE_STEP_PATIENT_CALL_FAILED = 'Apply Package Call Site Step Patient Call Failed',
  APPLY_PACKAGE_CALL_SITE_STEP_SITE_CONTACT_CALL_INITIATED = 'Apply Package Call Site Step Site Contact Call Initiated',
  APPLY_PACKAGE_CALL_SITE_STEP_SITE_CONTACT_CALL_CONNECTED = 'Apply Package Call Site Step Site Contact Call Connected',
  APPLY_PACKAGE_CALL_SITE_STEP_SITE_CONTACT_CALL_FAILED = 'Apply Package Call Site Step Site Contact Call Failed',
  APPLY_PACKAGE_CALL_SITE_STEP_SKIP_BUTTON_CLICKED = 'Apply Package Call Site Step Skip Button Clicked',
  APPLY_PACKAGE_CALL_SITE_STEP_SUBMIT_BUTTON_CLICKED = 'Apply Package Call Site Step Submit Button Clicked',
  APPLY_PACKAGE_CALL_TRIAL_BUTTON_CLICKED = 'Apply Package Call Trial Button Clicked',
  APPLY_PACKAGE_MESSAGE_TRIAL_BUTTON_CLICKED = 'Apply Package Message Trial Button Clicked',
  APPLY_PACKAGE_CONGRATS_DISMISS_BUTTON_CLICKED = 'Apply Package Congrats Dismiss Button Clicked',
  APPLY_PACKAGE_CONSENT_FOR_EMR_BUTTON_CLICKED = 'Apply Package Consent For EMR Button Clicked',
  APPLY_PACKAGE_CONSENT_FOR_EMR_CLOSED = 'Apply Package Consent For EMR Closed',
  APPLY_PACKAGE_CONSENT_FOR_EMR_COMPLETED = 'Apply Package Consent For EMR Completed',
  APPLY_PACKAGE_CONSENT_FOR_EMR_DECLINED = 'Apply Package Consent For EMR Declined',
  APPLY_PACKAGE_CONSENT_FOR_EMR_LOADED = 'Apply Package Consent For EMR Loaded',
  APPLY_PACKAGE_DOCTOR_STEP_SAVE_CLICKED = 'Apply Package Doctor Step Save Clicked',
  APPLY_PACKAGE_INSURANCE_STEP_SAVE_CLICKED = 'Apply Package Insurance Step Save Clicked',
  APPLY_PACKAGE_MEDICAL_RECORD_EMAIL_COPY_TEMPLATE_CLICKED = 'Apply Package Medical Record Email Copy Template Clicked',
  APPLY_PACKAGE_MEDICAL_RECORD_FILE_DELETED = 'Apply Package Medical Record File Deleted',
  APPLY_PACKAGE_MEDICAL_RECORD_FILE_UPLOADED = 'Apply Package Medical Record File Uploaded',
  APPLY_PACKAGE_MEDICAL_RECORD_FILE_VIEWED = 'Apply Package Medical Record File Viewed',
  APPLY_PACKAGE_MEDICAL_RECORD_SECTION_TOGGLE_CLOSED = 'Apply Package Medical Record Section Toggle Closed',
  APPLY_PACKAGE_MEDICAL_RECORD_SECTION_TOGGLE_OPENED = 'Apply Package Medical Record Section Toggle Opened',
  APPLY_PACKAGE_PATIENT_SUPPORT_CHAT_AGENT_SENT_MESSAGE = 'Apply Package Patient Support Chat Agent Sent Message',
  APPLY_PACKAGE_PATIENT_SUPPORT_CHAT_CHAT_BOX_EXPANDED = 'Apply Package Patient Support Chat Chat Box Expanded',
  APPLY_PACKAGE_PATIENT_SUPPORT_CHAT_CHAT_BOX_HIDDEN = 'Apply Package Patient Support Chat Chat Box Hidden',
  APPLY_PACKAGE_PATIENT_SUPPORT_CHAT_CHAT_BOX_MINIMIZED = 'Apply Package Patient Support Chat Chat Box Minimized',
  APPLY_PACKAGE_PATIENT_SUPPORT_CHAT_CHAT_BOX_SHOWN = 'Apply Package Patient Support Chat Chat Box Shown',
  APPLY_PACKAGE_PATIENT_SUPPORT_CHAT_PATIENT_SENT_MESSAGE = 'Apply Package Patient Support Chat Patient Sent Message',
  APPLY_PACKAGE_PATIENT_SUPPORT_CHAT_PATIENT_SENT_OFFLINE_MESSAGE = 'Apply Package Patient Support Chat Patient Sent Offline Message',
  APPLY_PACKAGE_PERSONAL_DETAILS_STEP_SAVE_CLICKED = 'Apply Package Personal Details Save Clicked',
  APPLY_PACKAGE_SUPPORTING_CONTACT_STEP_SAVE_CLICKED = 'Apply Package Supporting Contact Save Clicked',
  CALENDLY_APPOINTMENT_SCHEDULED = 'Calendly Appointment Scheduled', // Valueable Event - FB: Schedule
  CALENDLY_BUTTON_CLICKED = 'Calendly Button Clicked',
  CALENDLY_DATE_SELECTED = 'Calendly Date Selected',
  CLAIM_PROFILE_CLICKED = 'Claim Profile Clicked',
  CLAIM_SITE_CLICKED = 'Claim Site Clicked',
  DELETE_ACCOUNT_BUTTON_CLICKED = 'Delete Account Button Clicked',
  DIRECTORY_CARD_CLICKED = 'Directory Card Clicked', // Valueable Event - FB: ViewContent
  DIRECTORY_CARD_LIST_ITEM_CLICKED = 'Directory Card List Item Clicked',
  DIRECTORY_CLEAR_FILTERS_CLICKED = 'Directory Clear Filters Clicked',
  DIRECTORY_FILTER_MODIFIED = 'Directory Filter Modified',
  DIRECTORY_SEARCH_HERO_BUTTON_CLICKED = 'Directory Search Hero Button Clicked', // Valueable Event - FB: Search
  DIRECTORY_SEARCH_HISTORY_MODAL_VIEWED = 'Directory Search History Modal Viewed',
  DIRECTORY_SEARCH_HISTORY_APPLIED = 'Directory Search History Applied',
  DIRECTORY_SEARCH_HISTORY_DECLINED = 'Directory Search History Applied',
  DIRECTORY_SEARCH_INTERSTITIAL_BUTTON_CLICKED = 'Directory Search Interstitial Button Clicked',
  DIRECTORY_SEARCH_POPULAR_FILTERS_BUTTON_CLICKED = 'Directory Search Popular Filters Button Clicked',
  DIRECTORY_SEARCH_SEE_ALL_BUTTON_CLICKED = 'Directory Search See All Button Clicked',
  DIRECTORY_FILTER_PILL_CLICKED = 'Directory Filter Pill Clicked',
  DIRECTORY_SEARCH_BOX_MODIFIED = 'Directory Search Box Modified',
  DIRECTORY_SEARCH_FILTER_CLICKED = 'Directory Search Filter Clicked',
  DOCTOR_CARD_CLICKED = 'Doctor Card Clicked',
  DOCTOR_FAQ_ACCORDION_CLICKED = 'Doctor FAQ Accordion Clicked',
  DOCTOR_TOPIC_FAQ_ACCORDION_CLICKED = 'Doctor Topic FAQ Accordion Clicked',
  ELIGIBILITY_CHECKBOX_CLICKED = 'Eligibility Checkbox Clicked',
  EMR_RELEASE_ADDRESS_MANUAL_INPUT_CLICKED = 'EMR Release Address Manual Input Clicked',
  EMR_RELEASE_INPUT_MODIFIED = 'EMR Release Input Modified',
  EMR_RELEASE_VIDEO_OPENED = 'EMR Release Video Opened',
  EMR_RELEASE_CONSENT_ACCEPTED = 'EMR Release Consent Accepted',
  EMR_RELEASE_DETAILS_SUBMIT_CLICKED = 'EMR Release Details Submit Clicked',
  EMR_RELEASE_SECTION_SKIPPED = 'EMR Release Section Skipped',
  EMR_RELEASE_SIGNATURE_SUBMITTED = 'EMR Release Signature Submitted',
  EMR_RELEASE_SKIP_CLICKED = 'EMR Release Skip Clicked',
  EXPAND_ELIGIBILITY_CRITERIA_CLICKED = 'Expand Eligibility Criteria Clicked',
  EXPAND_TRIAL_SUMMARY_CLICKED = 'Expand Trial Summary Clicked',
  FAQ_CONJURED_FAQ_CLICKED = 'FAQ Conjured FAQ Clicked',
  FAQ_ASK_A_QUESTION_CLICKED = 'FAQ Ask A Question Clicked',
  FAQ_DOWNVOTE_CLICKED = 'FAQ Downvote Clicked',
  FAQ_UPVOTE_CLICKED = 'FAQ Upvote Clicked',
  FILTER_EMAIL_UPSELL_SHOWN = 'Filter Email Upsell Shown',
  GET_NOTIFIED_ABOUT_TRIALS_SUCCESS = 'Get Notified About Trials Success', // Valueable Event - FB: Subscribe
  HOSPITAL_FAQ_ACCORDION_CLICKED = 'Hospital FAQ Accordion Clicked',
  INBOX_CHANNEL_CREATED = 'Inbox Channel Created',
  INBOX_CHANNEL_CREATION_FAILED = 'Inbox Channel Creation Failed',
  INBOX_CHANNEL_SELECTED = 'Inbox Channel Selected',
  INBOX_DETAILS_PANEL_CLOSED = 'Inbox Details Panel Closed',
  INBOX_DETAILS_PANEL_OPENED = 'Inbox Details Panel Opened',
  INBOX_DETAILS_SITE_PHONE_CLICKED = 'Inbox Details Site Phone Clicked',
  INBOX_DETAILS_TRIAL_INFO_CLICKED = 'Inbox Details Trial Info Clicked',
  INBOX_DETAILS_VIEW_APPLY_CLICKED = 'Inbox Details View Apply Clicked',
  INBOX_DETAILS_VIEW_SITE_CLICKED = 'Inbox Details View Site Clicked',
  INBOX_MESSAGE_SENT = 'Inbox Message Sent',
  INBOX_PAT_NAV_AGENT_DETAILS_VIEWED = 'Inbox Pat Nav Agent Details Viewed',
  INBOX_PAT_NAV_AGENT_PAUSE_CLICKED = 'Inbox Pat Nav Agent Pause Button Clicked',
  INBOX_PAT_NAV_AGENT_PLAY_CLICKED = 'Inbox Pat Nav Agent Play Button Clicked',
  INBOX_PAT_NAV_AGENT_PLAYBACK_COMPLETED = 'Inbox Pat Nav Agent Playback Completed',
  INBOX_PAT_NAV_AGENT_TRANSCRIPTION_COPY_TO_CLIPBOARD_CLICKED = 'Inbox Pat Nav Agent Transcription Modal Copy To Clipboard Clicked',
  INBOX_PAT_NAV_AGENT_TRANSCRIPTION_MODAL_CLOSED = 'Inbox Pat Nav Agent Transcription Modal Closed',
  INBOX_PAT_NAV_AGENT_TRANSCRIPTION_MODAL_OPENED = 'Inbox Pat Nav Agent Transcription Modal Opened',
  INBOX_PROMPT_SELECTED = 'Inbox Prompt Selected',
  INBOX_SCREENING_APPOINTMENT_BOOKED = 'Inbox Screening Appointment Booked',
  INBOX_SCREENING_APPOINTMENT_OPTION_CLICKED = 'Inbox Screening Appointment Option Clicked',
  INBOX_SCREENING_APPOINTMENT_SELECTBOX_CHANGED = 'Inbox Screening Appointment Selectbox Changed',
  INBOX_SCREENING_CALENDAR_DOWNLOAD_CLICKED = 'Inbox Screening Calendar Download Clicked',
  INTERLINKED_GUIDE_ARTICLE_CLICKED = 'Interlinked Guide Article Clicked',
  INTERLINKED_TRIAL_CLICKED = 'Interlinked Trials Clicked',
  LOCATION_MAP_DOCTOR_CONTACT_NOW_CLICKED = 'Location Map Doctor Contact Now Clicked',
  LOCATION_MAP_DOCTOR_LEARN_MORE_CLICKED = 'Location Map Doctor Learn More Clicked',
  PAST_TRIAL_APPLICATION_CLICKED = 'Past Trial Application Clicked',
  PAST_APPLICATIONS_CTA_CLICKED = 'Past Applications CTA Clicked',
  PATIENT_BURGER_MENU_ITEM_CLICKED = 'Patient Burger Menu Item Clicked',
  PATIENT_NAVIGATOR_AGENT_CALL_ENDED = 'Patient Navigator Agent Call Ended',
  PATIENT_NAVIGATOR_AGENT_CALL_ERRORED = 'Patient Navigator Agent Call Errored',
  PATIENT_NAVIGATOR_AGENT_CALL_STARTED = 'Patient Navigator Agent Call Started',
  PATIENT_NAVIGATOR_AGENT_END_BUTTON_CLICKED = 'Patient Navigator Agent End Button Clicked',
  PATIENT_NAVIGATOR_AGENT_NEXT_STEP_BUTTON_CLICKED = 'Patient Navigator Agent Next Step Button Clicked',
  PATIENT_NAVIGATOR_AGENT_START_BUTTON_CLICKED = 'Patient Navigator Agent Start Button Clicked',
  PATIENT_REGISTRY_ENROLLMENT_CONSENT_SCROLLED = 'Patient Registry Enrollment Consent Scrolled',
  PATIENT_REGISTRY_ENROLLMENT_GUARDIAN_CLICKED = 'Patient Registry Enrollment Guardian Clicked',
  PATIENT_REGISTRY_ENROLLMENT_NO_CLICKED = 'Patient Registry Enrollment No Clicked',
  PATIENT_REGISTRY_ENROLLMENT_SELF_CLICKED = 'Patient Registry Enrollment Self Clicked',
  PATIENT_REGISTRY_ENROLLMENT_YES_CLICKED = 'Patient Registry Enrollment Yes Clicked',
  PATIENT_SIGNAL_OF_VALUE = 'Patient Signal Of Value',
  PHONE_RELAY_PIN_ENTERED = 'Phone Relay Pin Entered',
  POST_TRIAL_APPLY_BACK_NAVIGATION_CLICKED = 'Post Trial Apply Back Navigation Clicked',
  POST_TRIAL_APPLY_CALL_SITE_STEP_CALL_COMPLETED = 'Post Trial Apply Call Site Step Call Completed',
  POST_TRIAL_APPLY_CALL_SITE_STEP_ERRORED = 'Post Trial Apply Call Site Step Errored',
  POST_TRIAL_APPLY_CALL_SITE_STEP_PATIENT_CALL_INITIATED = 'Post Trial Apply Call Site Step Patient Call Initiated',
  POST_TRIAL_APPLY_CALL_SITE_STEP_PATIENT_CALL_CONNECTED = 'Post Trial Apply Call Site Step Patient Call Connected',
  POST_TRIAL_APPLY_CALL_SITE_STEP_PATIENT_CALL_FAILED = 'Post Trial Apply Call Site Step Patient Call Failed',
  POST_TRIAL_APPLY_CALL_SITE_STEP_SITE_CONTACT_CALL_INITIATED = 'Post Trial Apply Call Site Step Site Contact Call Initiated',
  POST_TRIAL_APPLY_CALL_SITE_STEP_SITE_CONTACT_CALL_CONNECTED = 'Post Trial Apply Call Site Step Site Contact Call Connected',
  POST_TRIAL_APPLY_CALL_SITE_STEP_SITE_CONTACT_CALL_FAILED = 'Post Trial Apply Call Site Step Site Contact Call Failed',
  POST_TRIAL_APPLY_CALL_SITE_STEP_SKIP_BUTTON_CLICKED = 'Post Trial Apply Call Site Step Skip Button Clicked',
  POST_TRIAL_APPLY_CALL_SITE_STEP_SUBMIT_BUTTON_CLICKED = 'Post Trial Apply Call Site Step Submit Button Clicked',
  POST_TRIAL_APPLY_CALL_SITE_STEP_SUBMITTED = 'Post Trial Apply Call Site Step Submitted',
  POST_TRIAL_APPLY_CALL_SITE_STEP_VIEWED = 'Post Trial Apply Call Site Step Viewed',
  POST_TRIAL_APPLY_DOCTOR_PICKER_SUBMIT_BUTTON_CLICKED = 'Post Trial Apply Doctor Picker Submit Button Clicked',
  POST_TRIAL_APPLY_EXIT_NAVIGATION_CLICKED = 'Post Trial Apply Exit Navigation Clicked',
  POST_TRIAL_APPLY_NEXT_BUTTON_CLICKED = 'Post Trial Apply Next Button Clicked',
  POST_TRIAL_APPLY_QUESTION_MODIFIED = 'Post Trial Apply Question Modified',
  POST_TRIAL_APPLY_QUESTION_SKIPPED = 'Post Trial Apply Question Skipped',
  POST_TRIAL_APPLY_QUESTION_VIEWED = 'Post Trial Apply Question Viewed',
  POST_TRIAL_APPLY_SHARE_STORY_CONSENT_SUBMIT_BUTTON_CLICKED = 'Post Trial Apply Share Story Submit Button Clicked',
  POST_TRIAL_APPLY_SHARE_STORY_MODAL_OPENED = 'Post Trial Apply Share Story Modal Opened',
  POST_TRIAL_APPLY_SHARE_STORY_MODAL_DONE_CLICKED = 'Post Trial Apply Share Story Modal Done Clicked',
  POST_TRIAL_APPLY_SHARE_TRIAL_UPLOAD_PICTURE_CLICKED = 'Post Trial Apply Share Trial Upload Picture Clicked',
  POST_TRIAL_APPLY_SHARE_TRIAL_UPLOAD_PICTURE_FILES_PICKED = 'Post Trial Apply Share Trial Upload Picture Files Picked',
  POST_TRIAL_APPLY_SITE_PHONE_NUMBER_COPY_CLICKED = 'Post Trial Apply Site Phone Number Copy To Clipboard Clicked',
  POST_TRIAL_APPLY_STEP_VIEWED = 'Post Trial Apply Step Viewed',
  POST_TRIAL_APPLY_SUBMIT_BUTTON_CLICKED = 'Post Trial Apply Submit Button Clicked',
  POST_TRIAL_APPLY_WHY_APPLY_SUBMIT_BUTTON_CLICKED = 'Post Trial Apply Why Apply Submit Button Clicked',
  PRODUCT_TOUR_STEP_COMPLETED = 'Product Tour Step Completed',
  PRODUCT_TOUR_COMPLETED = 'Product Tour Completed',
  PRODUCT_TOUR_DISMISSED = 'Product Tour Dismissed',
  PROVIDER_LANDER_CTA_CLICKED = 'Provider Lander CTA Clicked',
  REFERRAL_COPY_LINK_CLICKED = 'Referral Copy Link Clicked',
  REQUEST_SIGN_IN = 'Request Sign In',
  RESEARCHER_LANDER_CTA_CLICKED = 'Researcher Lander CTA Clicked',
  SEARCH_EMPTY_STATE_VIEWED = 'Search Empty State Viewed', // Valueable Event - FB: Search
  SEARCH_PAGE_NUMBER_CLICKED = 'Search Page Number Clicked', // Valueable Event - FB: Search
  SEARCH_PAGINATION_NEXT_CLICKED = 'Search Pagination Next Clicked',
  SEARCH_PAGINATION_PREVIOUS_CLICKED = 'Search Pagination Previous Clicked',
  SHARE_TRIAL_MODAL_CLOSED = 'Share Trial Modal Closed',
  SHARE_TRIAL_MODAL_OPENED = 'Share Trial Modal Opened', // Valueable Event - FB: Donate
  SHARE_TRIAL_VIA_COPY_LINK_CLICKED = 'Share Trial Via Copy Link Clicked',
  SHARE_TRIAL_VIA_EMAIL_CLICKED = 'Share Trial Via Email Clicked',
  SHARE_TRIAL_VIA_FACEBOOK_SHARE_CLICKED = 'Share Trial Via Facebook Share Clicked',
  SHARE_TRIAL_VIA_FACEBOOK_MESSENGER_CLICKED = 'Share Trial Via Facebook Messenger Clicked',
  SHARE_TRIAL_VIA_LINKED_IN_CLICKED = 'Share Trial Via LinkedIn Share Clicked',
  SHARE_TRIAL_VIA_REDDIT_CLICKED = 'Share Trial Via Reddit Share Clicked',
  SHARE_TRIAL_VIA_TWITTER_CLICKED = 'Share Trial Via Twitter Share Clicked',
  SHOW_ORIGINAL_ELIGIBILITY_CRITERIA_CLICKED = 'Show Original Eligibility Criteria Clicked',
  SHOW_SUMMARY_ELIGIBILITY_CRITERIA_CLICKED = 'Show Summary Eligibility Criteria Clicked',
  SIDE_EFFECTS_ARM_GROUP_CHANGED = 'Side Effects Arm Group Changed',
  SIDE_EFFECTS_SEVERITY_CHANGED = 'Side Effects Severity Changed',
  SIGN_OUT_BUTTON_CLICKED = 'Sign Out Button Clicked',
  SIGNED_IN_PATIENT_WEBSITE_SITE_STAFF_PORTAL_BUTTON_CLICKED = 'Signed In Patient Website Site Staff Portal Button Clicked',
  SIGNED_IN_PATIENT_WEBSITE_BECOME_TRIAL_PRO_BUTTON_CLICKED = 'Signed In Patient Website Become Trial Pro Button Clicked',
  SPONSORS_LANDER_CONDITION_SELECTED = 'Sponsors Lander Condition Selected',
  SPONSORS_LANDER_CTA_CLICKED = 'Sponsors Lander CTA Clicked',
  SPONSORS_LANDER_RECRUITMENT_DEADLINE_SELECTED = 'Sponsors Lander Recruitment Deadline Selected',
  SPONSORS_LANDER_TRIAL_SELECTED = 'Sponsors Lander Trial Selected',
  SUMMARIZE_TRIAL_DESCRIPTION_CLICKED = 'Summarize Trial Description Clicked', // Valueable Event - FB: ViewContent
  TREATMENT_TOPIC_CATEGORY_CARD_CLICKED = 'Treatment Topic Category Card Clicked',
  PATIENT_CLIENT_ERROR_BOUNDARY_HIT = 'Patient Client Error Boundary',
  TRIAL_APPLICATION_BACK_NAVIGATION_CLICKED = 'Trial Application Back Navigation Clicked',
  TRIAL_APPLICATION_COMPLETE_SUBMIT_BUTTON_CLICKED = 'Trial Application Complete Submit Button Clicked',
  TRIAL_APPLICATION_EXIT_NAVIGATION_CLICKED = 'Trial Application Exit Navigation Clicked',
  TRIAL_APPLICATION_NEXT_BUTTON_CLICKED = 'Trial Application Next Button Clicked',
  TRIAL_APPLICATION_PARTIAL_SUBMIT_BUTTON_CLICKED = 'Trial Application Partial Submit Button Clicked', // Halfway Mark - CompleteRegistration
  TRIAL_APPLICATION_PARTIAL_SUBMITTED_VIA_CLIENT = 'Trial Application Partial Submitted Via Client', // Halfway Mark - CompleteRegistration
  TRIAL_APPLICATION_QUESTION_MODIFIED = 'Trial Application Question Modified',
  TRIAL_APPLICATION_QUESTION_SKIPPED = 'Trial Application Question Skip Clicked',
  TRIAL_APPLICATION_QUESTION_VIEWED = 'Trial Application Question Viewed',
  TRIAL_APPLICATION_SECONDARY_BUTTON_CLICKED = 'Trial Application Secondary Button Clicked',
  TRIAL_APPLICATION_SEARCH_INPUT_CHANGED = 'Trial Application Search Input Changed',
  TRIAL_APPLICATION_SEARCH_ITEM_SELECTED = 'Trial Application Search Item Selected',
  TRIAL_APPLICATION_SEARCH_ITEM_REMOVED = 'Trial Application Search Item Removed',
  TRIAL_APPLICATION_SEARCH_POPULAR_ITEM_SELECTED = 'Trial Application Search Popular Item Selected',
  TRIAL_APPLICATION_START_BUTTON_CLICKED = 'Trial Application Start Button Clicked',
  TRIAL_APPLICATION_SUBMIT_BUTTON_CLICKED = 'Trial Application Submit Button Clicked',
  TRIAL_APPLICATION_SUBMITTED = 'Trial Application Submitted', // Valueable Event - FB: SubmitApplication
  TRIAL_APPLICATION_CONDITION_MATCHED_SUBMITTED = 'Trial Application Condition Matched Submitted', // Valuable Event - can be mapped to Payment Event
  TRIAL_APPLICATION_SUBMITTED_VIA_CLIENT = 'Trial Application Submitted Via Client', // Valueable Event - FB: SubmitApplication
  TRIAL_APPLICATION_QUALIFIED_SUBMITTED = 'Trial Application Qualified Submitted', // Valueable Event - can be mapped to Payment Event
  TRIAL_LISTING_CLICKED = 'Trial Listing Clicked', // Valueable Event - FB: ViewContent
  TRIAL_PRO_AGE_RANGE_FILTER_CHANGED = 'Trial Pro Age Range Filter Changed',
  TRIAL_PRO_BOOK_ONBOARDING_CALL_CLICKED = 'Trial Pro Book Onboarding Call Clicked',
  TRIAL_PRO_APPLICATION_OWNER_FILTER_CHANGED = 'Trial Pro Application Owner Filter Changed',
  TRIAL_PRO_COMPLETION_SCREEN_CLAIM_ANOTHER_TRIAL_BUTTON_CLICKED = 'Trial Pro Completion Screen Claim Another Trial Button Clicked',
  TRIAL_PRO_COMPLETION_SCREEN_EXIT_BUTTON_CLICKED = 'Trial Pro Completion Screen Exit Button Clicked',
  TRIAL_PRO_CONDITIONS_FILTER_CHANGED = 'Trial Pro Conditions Filter Changed',
  TRIAL_PRO_DISTANCE_FILTER_CHANGED = 'Trial Pro Distance Filter Changed',
  TRIAL_PRO_DOSSIER_ACCORDION_CLOSED = 'Trial Pro Dossier Accordion Closed',
  TRIAL_PRO_DOSSIER_ACCORDION_OPENED = 'Trial Pro Dossier Accordion Opened',
  TRIAL_PRO_DOSSIER_AUTH_ERROR = 'Trial Pro Dossier Auth Error',
  TRIAL_PRO_DOSSIER_AUTH_SENT_LOGIN_LINK = 'Trial Pro Dossier Auth Sent Login Link',
  TRIAL_PRO_DOSSIER_AUTH_SUCCESSFUL = 'Trial Pro Dossier Auth Successful',
  TRIAL_PRO_DOSSIER_AUTH_USER_CREATE_ERROR = 'Trial Pro Dossier Auth User Create Error',
  TRIAL_PRO_DOSSIER_ELIGIBILITY_RESPONSE_UPDATED = 'Trial Pro Dossier Eligibility Response Updated',
  TRIAL_PRO_DOSSIER_ELIGIBILITY_VERIFICATION_METHOD_UPDATED = 'Trial Pro Dossier Eligibility Verification Method Updated',
  TRIAL_PRO_DOSSIER_NOTE_CREATED = 'Trial Pro Dossier Note Created',
  TRIAL_PRO_DOSSIER_SCHEDULING_MODAL_OPENED = 'Trial Pro Dossier Scheduling Modal Opened',
  TRIAL_PRO_DOSSIER_SCHEDULING_MODAL_SUBMITTED = 'Trial Pro Dossier Scheduling Modal Submitted',
  TRIAL_PRO_DOSSIER_TAB_CHANGED = 'Trial Pro Dossier Tab Changed',
  TRIAL_PRO_DOSSIER_TASK_ASSIGNED = 'Trial Pro Dossier Task Assigned',
  TRIAL_PRO_DOSSIER_TASK_COMPLETED = 'Trial Pro Dossier Task Completed',
  TRIAL_PRO_DOSSIER_TASK_UNCOMPLETED = 'Trial Pro Dossier Task Uncompleted',
  TRIAL_PRO_DOSSIER_VIEW = 'Trial Pro Dossier View',
  TRIAL_PRO_ELIGIBILITY_FILTER_CHANGED = 'Trial Pro Eligibility Filter Changed',
  TRIAL_PRO_EXPORT_MODAL_OPENED = 'Trial Pro Export Modal Opened',
  TRIAL_PRO_FILTER_MODAL_OPENED = 'Trial Pro Filter Modal Opened',
  TRIAL_PRO_GET_STARTED_BOOKING_FALLBACK = 'Trial Pro Get Started Booking Fallback',
  TRIAL_PRO_GET_STARTED_NEXT_STEP = 'Trial Pro Get Started Next Step',
  TRIAL_PRO_GET_STARTED_PREV_STEP = 'Trial Pro Get Started Prev Step',
  TRIAL_PRO_GET_STARTED_SITE_CREATED = 'Trial Pro Get Started Site Created',
  TRIAL_PRO_GET_STARTED_SITE_SELECTED = 'Trial Pro Get Started Site Selected',
  TRIAL_PRO_GET_STARTED_SITE_UNCREATED = 'Trial Pro Get Started Site Un-Created',
  TRIAL_PRO_GET_STARTED_SITE_UNSELECTED = 'Trial Pro Get Started Site Un-Selected',
  TRIAL_PRO_GET_STARTED_TRIALS_AND_SITES_CLAIMED = 'Trial Pro Get Started Trals And Sites Claimed',
  TRIAL_PRO_GET_STARTED_TRIAL_SELECTED = 'Trial Pro Get Started Trial Selected',
  TRIAL_PRO_GET_STARTED_TRIAL_UNSELECTED = 'Trial Pro Get Started Trial Un-Selected',
  TRIAL_PRO_GET_STARTED_USER_CREATED = 'Trial Pro Get Started User Created',
  TRIAL_PRO_IN_PRODUCT_EMR_UPSELL_CLICKED = 'Trial Pro In Product EMR Upsell Clicked',
  TRIAL_PRO_MESSAGES_BUTTON_CLICKED = 'Trial Pro Message Button Clicked',
  TRIAL_PRO_MATCHES_EMR_UPSELL_CLICKED = 'Trial Pro In Product EMR Upsell Clicked',
  TRIAL_PRO_NAV_BAR_SELECT_SITE_CLICKED = 'Trial Pro Nav Bar Select Site Clicked',
  TRIAL_PRO_NAV_BAR_SELECT_TRIAL_CLICKED = 'Trial Pro Nav Bar Select Trial Clicked',
  TRIAL_PRO_PATIENT_DOCUMENT_CLICKED = 'Trial Pro Patient Document Clicked',
  TRIAL_PRO_PATIENT_PROFILE_REQUEST_EMR_FAILED = 'Trial Pro Patient Profile Request EMR Failed',
  TRIAL_PRO_PATIENT_PROFILE_REQUEST_EMR_SUCCEEDED = 'Trial Pro Patient Profile Request EMR Succeeded',
  TRIAL_PRO_PATIENT_PROFILE_TAB_CHANGED = 'Trial Pro Patient Profile Tab Changed',
  TRIAL_PRO_PRE_SCREENER_ADD_CRITERIA_COMPLETED = 'Trial Pro Pre Screener Add Criteria Completed',
  TRIAL_PRO_PRE_SCREENER_ADD_CRITERIA_STARTED = 'Trial Pro Pre Screener Add Criteria Started',
  TRIAL_PRO_PRE_SCREENER_ADD_EXCLUSION_BUTTON_CLICKED = 'Trial Pro Pre Screener Add Exclusion Button Clicked',
  TRIAL_PRO_PRE_SCREENER_ADD_INCLUSION_BUTTON_CLICKED = 'Trial Pro Pre Screener Add Inclusion Button Clicked',
  TRIAL_PRO_PRE_SCREENER_PREVIEW_LIVE_CLICKED = 'Trial Pro Pre Screener Preview Live Clicked',
  TRIAL_PRO_PRE_SCREENER_REMOVED_CRITERIA = 'Trial Pro Pre Screener Removed Criteria',
  TRIAL_PRO_PRE_SCREENER_SAVED = 'Trial Pro Pre Screener Saved',
  TRIAL_PRO_PRE_SCREENER_TOGGLED = 'Trial Pro Pre Screener Toggled',
  TRIAL_PRO_ONBOARDING_BACK_BUTTON_CLICKED = 'Trial Pro Onboarding Back Navigation Clicked',
  TRIAL_PRO_ONBOARDING_CONDITION_SEARCH_UPDATED = 'Trial Pro Onboarding Condition Search Updated',
  TRIAL_PRO_ONBOARDING_FORM_SUBMITTED_VIA_CLIENT = 'Trial Pro Onboarding Form Submitted Via Client',
  TRIAL_PRO_ONBOARDING_NEXT_BUTTON_CLICKED = 'Trial Pro Onboarding Next Button Clicked',
  TRIAL_PRO_ONBOARDING_QUESTION_MODIFIED = 'Trial Pro Onboarding Question Modified',
  TRIAL_PRO_ONBOARDING_QUESTION_VIEWED = 'Trial Pro Onboarding Question Viewed',
  TRIAL_PRO_ONBOARDING_REGISTRY_BUTTON_CLICKED = 'Trial Pro Onboarding Registry Button Clicked',
  TRIAL_PRO_ONBOARDING_SKIP_BUTTON_CLICKED = 'Trial Pro Onboarding Skip Button Clicked',
  TRIAL_PRO_ONBOARDING_SUBMIT_BUTTON_CLICKED = 'Trial Pro Onboarding Submit Button Clicked',
  TRIAL_PRO_ONBOARDING_TRIAL_SEARCH_UPDATED = 'Trial Pro Onboarding Trial Search Updated',
  TRIAL_PRO_REFERRALS_APPLICATION_DISQUALIFIED_REASON_SELECTED = 'Trial Pro Referrals Application Disqualification Reason Selected',
  TRIAL_PRO_REFERRALS_APPLICATION_FOLLOWUP_DATE_SELECTED = 'Trial Pro Referrals Application Follow Up Date Selected',
  TRIAL_PRO_REFERRALS_APPLICATION_FOLLOWUP_TIME_SELECTED = 'Trial Pro Referrals Application Follow Up Time Selected',
  TRIAL_PRO_REFERRALS_APPLICATION_HAD_SCREENING_VISIT_SELECTED = 'Trial Pro Referrals Application Had Screening Visit Selected',
  TRIAL_PRO_REFERRALS_APPLICATION_OWNER_UPDATED = 'Trial Pro Referrals Application Owner Updated',
  TRIAL_PRO_REFERRALS_APPLICATION_SCREENING_DATE_SELECTED = 'Trial Pro Referrals Application Screening Date Selected',
  TRIAL_PRO_REFERRALS_APPLICATION_SCREENING_TIME_SELECTED = 'Trial Pro Referrals Application Screening Time Selected',
  TRIAL_PRO_REFERRALS_APPLICATION_STATUS_MODAL_OPENED = 'Trial Pro Referrals Application Status Modal Opened',
  TRIAL_PRO_REFERRALS_APPLICATION_STATUS_UPDATED = 'Trial Pro Referrals Application Status Updated',
  TRIAL_PRO_REFERRALS_APPLICATION_WILL_NOT_ENROLL_TYPE_SELECTED = 'Trial Pro Referrals Application Will Not Enroll Type Selected',
  TRIAL_PRO_REFERRALS_APPLY_NOTE_CREATED = 'Trial Pro Referrals Apply Note Created',
  TRIAL_PRO_REFERRALS_CONSENT_FAXED_TO_DOCTOR_FAILED = 'Trial Pro Referrals Consent Faxed To Doctor Failed',
  TRIAL_PRO_REFERRALS_CONSENT_FAXED_TO_DOCTOR_SUCCEEDED = 'Trial Pro Referrals Consent Faxed To Doctor Succeeded',
  TRIAL_PRO_REFERRALS_CREDITS_MODAL_OPENED = 'Trial Pro Referrals Credits Modal Opened',
  TRIAL_PRO_REFERRALS_CREDITS_MODAL_SUBMITTED = 'Trial Pro Referrals Credits Modal Submitted',
  TRIAL_PRO_REFERRALS_DISTANCE_QUICK_FILTER_OPENED = 'Trial Pro Referrals Distance Quick Filter Opened',
  TRIAL_PRO_REFERRALS_DOCTOR_DETAILS_UPDATED = 'Trial Pro Referrals Doctor Details Updated',
  TRIAL_PRO_REFERRALS_DOSSIER_OPENED = 'Trial Pro Referrals Dossier Opened',
  TRIAL_PRO_REFERRALS_DOSSIER_URL_COPIED = 'Trial Pro Referrals Dossier URL Copied',
  TRIAL_PRO_REFERRALS_ELIGIBILITY_RESPONSE_UPDATED = 'Trial Pro Referrals Eligibility Response Updated',
  TRIAL_PRO_REFERRALS_ELIGIBILITY_VERIFICATION_METHOD_UPDATED = 'Trial Pro Referrals Eligibility Verification Method Updated',
  TRIAL_PRO_REFERRALS_EMR_CONSENT_DOWNLOADED = 'Trial Pro Referrals EMR Consent Downloaded',
  TRIAL_PRO_REFERRALS_EMR_CONSENT_LINK_COPIED = 'Trial Pro Referrals EMR Consent Link Copied',
  TRIAL_PRO_REFERRALS_EMR_CONSENT_SUBMITTED = 'Trial Pro Referrals EMR Consent Submitted',
  TRIAL_PRO_REFERRALS_FILTER_CHANGED = 'Trial Pro Referrals Filter Changed',
  TRIAL_PRO_REFERRALS_FILTER_MENU_OPENED = 'Trial Pro Referrals Filter Menu Opened',
  TRIAL_PRO_REFERRALS_MEDICAL_RECORD_FILE_UPLOADED = 'Trial Pro Referrals Medical Record File Uploaded',
  TRIAL_PRO_REFERRALS_NEW_EMR_CONSENT_SENT = 'Trial Pro Referrals New EMR Consent Sent',
  TRIAL_PRO_REFERRALS_PATIENT_EMAIL_COPIED = 'Trial Pro Referrals Patient Email Copied',
  TRIAL_PRO_REFERRALS_PATIENT_DOCUMENTS_SECTION_VIEWED = 'Trial Pro Referrals Patient Documents Section Viewed',
  TRIAL_PRO_REFERRALS_PATIENT_IDENTIFIER_COPIED = 'Trial Pro Referrals Patient Identifier Copied',
  TRIAL_PRO_REFERRALS_PATIENT_PHONE_COPIED = 'Trial Pro Referrals Patient Phone Copied',
  TRIAL_PRO_REFERRALS_SELECTED_CLINICAL_LOCATION_FILTER_CHANGED = 'Trial Pro Referrals Selected Clinical Location Filter Changed',
  TRIAL_PRO_REFERRALS_SELECTED_SITE_FILTER_CHANGED = 'Trial Pro Referrals Selected Site Filter Changed',
  TRIAL_PRO_REFERRALS_SELECTED_TRIAL_FILTER_CHANGED = 'Trial Pro Referrals Selected Trial Filter Changed',
  TRIAL_PRO_REFERRALS_SITE_QUICK_FILTER_OPENED = 'Trial Pro Referrals Site Quick Filter Opened',
  TRIAL_PRO_REFERRALS_SORT_MENU_OPENED = 'Trial Pro Referrals Sort Menu Opened',
  TRIAL_PRO_REFERRALS_SORTING_CHANGED = 'Trial Pro Referrals Sorting Changed',
  TRIAL_PRO_REFERRALS_STAGE_QUICK_FILTER_OPENED = 'Trial Pro Referrals Stage Quick Filter Opened',
  TRIAL_PRO_REFERRALS_STATUS_FILTER_CHANGED = 'Trial Pro Referrals Status Filter Changed',
  TRIAL_PRO_REFERRALS_TRIAL_APPLICATION_MODAL_OPENED = 'Trial Pro Referrals Trial Application Modal Opened',
  TRIAL_PRO_REFERRALS_TRIAL_QUICK_FILTER_OPENED = 'Trial Pro Referrals Trial Quick Filter Opened',
  TRIAL_PRO_REGISTRY_CLEAR_FILTERS_BUTTON_CLICKED = 'Trial Pro Registry Clear Filters Button Clicked',
  TRIAL_PRO_REGISTRY_FILTER_APPLIED = 'Trial Pro Registry Filter Applied',
  TRIAL_PRO_REGISTRY_INVITATION_EMAIL_CLICKED = 'Trial Pro Registry Invitation Email Clicked',
  TRIAL_PRO_REGISTRY_INVITATION_EMAIL_OPENED = 'Trial Pro Registry Invitation Email Opened',
  TRIAL_PRO_REGISTRY_INVITATION_EMAIL_SENT = 'Trial Pro Registry Invitation Email Sent',
  TRIAL_PRO_REGISTRY_INVITATION_SMS_OPENED = 'Trial Pro Registry Invitation SMS Opened',
  TRIAL_PRO_REGISTRY_INVITATION_SMS_SENT = 'Trial Pro Registry Invitation SMS Sent',
  TRIAL_PRO_REGISTRY_INVITE_BUTTON_CLICKED_MODAL_OPENED = 'Trial Pro Registry Invite Button Clicked',
  TRIAL_PRO_REGISTRY_INVITE_FAILURE_NOTIFICATION = 'Trial Pro Registry Invite Failure Notification',
  TRIAL_PRO_REGISTRY_INVITE_NO_CREDITS_RETURNED = 'Trial Pro Registry Invite Patient Modal No Credits Returned',
  TRIAL_PRO_REGISTRY_INVITE_SUBMITTED = 'Trial Pro Registry Invite Patient Modal Submitted',
  TRIAL_PRO_REGISTRY_INVITE_SUCCESS_NOTIFICATION = 'Trial Pro Registry Invite Success Notification',
  TRIAL_PRO_REGISTRY_PAGE_LOADED_EMPTY_STATE = 'Trial Pro Registry Page Loaded Empty State',
  TRIAL_PRO_REGISTRY_PAGE_LOADED_WITH_RESULTS = 'Trial Pro Registry Page Loaded With Results',
  TRIAL_PRO_REGISTRY_PASS_MODAL_OPENED = 'Trial Pro Registry Pass Patient Modal Opened',
  TRIAL_PRO_REGISTRY_PASS_MODAL_SUBMITTED = 'Trial Pro Registry Pass Patient Modal Submitted',
  TRIAL_PRO_REGISTRY_PATIENT_CARD_SHARED = 'Trial Pro Registry Patient Card Shared',
  TRIAL_PRO_REGISTRY_PATIENT_DETAILS_MODAL_OPENED = 'Trial Pro Registry Patient Details Modal Opened',
  TRIAL_PRO_REGISTRY_REQUEST_INVITATIONS_MODAL_OPENED = 'Trial Pro Registry Request Invitations Modal Opened',
  TRIAL_PRO_REGISTRY_REQUEST_INVITATIONS_MODAL_SUBMITTED = 'Trial Pro Registry Request Invitations Modal Submitted',
  TRIAL_PRO_REGISTRY_SEARCH_EXECUTED = 'Trial Pro Registry Search Executed',
  TRIAL_PRO_REGISTRY_SELECTED_SITE_FILTER_CHANGED = 'Trial Pro Registry Selected Site Filter Changed',
  TRIAL_PRO_REGISTRY_SELECTED_TRIAL_FILTER_CHANGED = 'Trial Pro Registry Selected Trial Filter Changed',
  TRIAL_PRO_SEX_AT_BIRTH_FILTER_CHANGED = 'Trial Pro Sex At Birth Filter Changed',
  TRIAL_PRO_SITE_ADDED = 'Trial Pro Site Added',
  TRIAL_PRO_TRIAL_SETTINGS_QUESTION_SUBMITTED = 'Trial Pro Trial Settings Question Submitted',
  TRIAL_PRO_UNVERIFIED_RFERRALS_REGISTRY_BUTTON_CLICKED = 'Trial Pro Unverified Referrals Registry Button Clicked',
  TRIAL_PRO_UPCOMING_PAGE_MODAL_OPENED = 'Trial Pro Upcoming Page Modal Opened',
  TRIAL_PRO_UPCOMING_PAGE_MODAL_SUBMITTED = 'Trial Pro Upcoming Page Modal Submitted',
  VIDEO_CALL_CLIENT_INITIATED = 'Video Call Client Initiated',
  VIDEO_CALL_OPEN_BUTTON_CLICKED = 'Video Call Open Button Clicked',
  VIDEO_CALL_PATIENT_ENTERED_WAITING_ROOM = 'Video Call Patient Entered Waiting Room',
  VIDEO_CALL_PATIENT_JOINED = 'Video Call Patient Joined',
  VIDEO_CALL_BUTTON_SHOWN = 'Video Call Button Shown',
  VIDEO_CALL_PATIENT_LEFT = 'Video Call Patient Left',
  VIDEO_PLAYED = 'Video Played',
  VIEW_OTHER_TRIALS_CLICKED = 'View Other Trials Clicked',
  VIEW_TRIALS_CLICKED = 'View Trials Clicked', // Valueable Event - FB: ViewContent
}

// Only use pageview reserve word properties for their intended meaning
// Segment always expects path to be a page’s URL path https://segment.com/docs/connections/spec/page/
export const trackPageview = (router: NextRouter) => {
  const pageProps = getPageTrackPropsFromRouter(router)

  trackPageviewForPageProps(pageProps)
}

export const trackPageviewForPageProps = (pageProps: {
  path: string
  queryParams: {
    [x: string]: string
  }
  route: string
}) => {
  analytics.page(pageProps)
}

/**
 * Only use reserve word properties for their intended meaning (revenue!) https://segment.com/docs/connections/spec/track/#properties
 */
export type SegmentReserveWordProperties = {
  currency?: string
  revenue?: number
  value?: number
}
type AvoidSegmentReserveWordProperties = {
  currency?: never
  revenue?: never
  value?: never
}

export type IntegrationOptions = {
  integrations: {
    [key: string]: boolean
  }
}

/**
 * Please add context-specific properties for events. Don't be afraid of being too verbose - we want to optimize for
 * insight discovery when looking at the logs!
 */
export type TrackingProperties = {
  actionSource?: string
  clinicalLocationId?: number
  content_category?: string // Segement is unable to convert to snake_case which is required by Facebook
  context?: TrackContext
  doctorSlug?: string
  durationInSeconds?: number
  email?: string
  index?: number
  nctId?: string
  path?: string
  patientIsQualified?: boolean
  patientTrialSearchCondition?: string
  patientTrialSearchPageNumber?: string
  patientTrialSearchResultCount?: string
  phoneRelayDigitsInput?: string
  products?: {
    product_id: string
    quantity: number
  }[]
  productTourStep?: number
  questionnaireAttribute?: string
  questionnaireHasMonetizedTrialSite?: boolean
  questionnaireInputValue?: ExtendedFormDataPoint
  questionnaireOptionCount?: number
  questionnaireOptionLabels?: string[]
  questionnaireOptionValues?: string[]
  source?: TrackingSource
  sspFilterState?: FilterState
  sspFilterStateSource?: string
  sspReferralsPageReferralCount?: number
  sspReferralsSortApplied?: boolean
  sspRegistryPatientResults?: number[]
  stringifiedUrlParams?: string
  traits?: Traits
  trialApplicationId?: number
  trialApplicationIdToken?: string
  trialLocationId?: number
  trialLocationIds?: number[]
  trialSlug?: string
  type?: string | number
  userId?: string
  val?: string | number
} & AvoidSegmentReserveWordProperties

// https://segment.com/docs/connections/destinations/catalog/google-analytics/#utm-parameters
export type TrackContext = {
  campaign?: {
    content?: string
    medium: string
    name: string
    referrer?: string
    source: string
    term?: string
  }
  traits?: Traits
}

/**
 * Non-isomorphic, client side tracking for outbound analytic events to Segemnt.
 */
export const trackEvent = (
  eventName: TrackingEvent,
  properties?: TrackingProperties,
  segmentReserveProperties?: SegmentReserveWordProperties,
  options?: IntegrationOptions,
) => {
  const search =
    typeof window !== 'undefined' && window ? window.location?.search ?? '' : ''
  const path =
    typeof window !== 'undefined' && window
      ? window.location?.pathname ?? '/'
      : '/'
  const urlParams = new URLSearchParams(search)
  const searchParamsObject = Object.fromEntries(urlParams.entries())

  // extract userIdToken as userId from JWT cookie, identify the user if we haven't already
  // FIXME: could have impersonation attacks but this is just event tracking so should be ok
  const userIdToken = getUserIdTokenFromJwtCookie()
  if (userIdToken) {
    const segmentUserIdFromCookie = getSegmentUserIdFromCookie()
    if (userIdToken !== segmentUserIdFromCookie) {
      identifyUser({ userId: userIdToken }) // make sure we tell analytics we have a logged in user
    }
  }

  const propertiesWithPath = {
    ...searchParamsObject,
    ...properties,
    path,
  }

  const allOptions = { ...defaultIntegrationOptions, ...options }
  const allProperties = { ...propertiesWithPath, ...segmentReserveProperties }
  analytics.track(eventName, allProperties, allOptions)

  if (isHotJarPresent() && typeof window.hj === 'function') {
    // We don't get props with HotJar, just event names that must be A-Za-z0-9\-_
    window.hj('event', underscoreForHotJar(eventName))
  }
}

// we want to avoid sending all events to facebook
export const defaultIntegrationOptions: IntegrationOptions = {
  integrations: {
    'Facebook Conversions API': false,
    'Facebook Pixel': false,
  },
}

export const sendEventToFacebook: IntegrationOptions = {
  integrations: {
    'Facebook Conversions API': true,
    'Facebook Pixel': true,
  },
}

export const emptySegmentReserveProperties = {}
/**
 * Isomorphic event tracking for outbound analytic events to Segment and Facebook.
 *
 * @param eventName
 * @param properties
 * @param integrationOptions
 */
export function trackEventWithFacebook(
  eventName: TrackingEvent,
  properties?: TrackingProperties,
  integrationOptions?: IntegrationOptions,
) {
  return trackEvent(eventName, properties, emptySegmentReserveProperties, {
    ...sendEventToFacebook,
    ...integrationOptions,
  })
}

const isHotJarPresent = () => {
  return (
    isProcessClientSide() &&
    typeof window !== 'undefined' &&
    window &&
    window.hj &&
    typeof window.hj === 'function'
  )
}

// https://segment.com/docs/connections/spec/identify/#traits
type Traits = {
  address?: {
    city?: string // newyork (no spaces or punctuation)
    country?: string
    postalCode?: string // Use lowercase with no spaces and no dash. Use only the first 5 digits for U.S. zip codes.
    state?: string // Use the 2-character ANSI abbreviation code in lowercase.
    street?: string
  }
  age?: number
  birthday?: Date
  email?: string
  firstName?: string //Normalized format: mary
  gender?: string // f for female,  m for male
  id?: string
  lastName?: string
  name?: string
  phone?: string // Normalized format: 16505551212
  title?: string
  username?: string
  zipcode?: string
}

/**
 * Get the segment anonymous id by either looking at the cookie or falling back to the client side code
 * @see https://segment.com/docs/connections/spec/best-practices-identify/
 */
export const getUserSegmentAnonymousId = () => {
  return extractSegmentAnonymousIdFromCookie() ?? analytics.user().anonymousId
}

/**
 *  Every identify call must have a User ID or an Anonymous ID. This allows us to attribute anonymous users to their immutable user id
 */
export const identifyUser = ({
  anonymousId,
  traits,
  userId,
}: {
  anonymousId?: string
  traits?: Traits
  userId: string
}) => {
  if (isProcessClientSide()) {
    analytics.identify(userId, traits)
  } else if (anonymousId) {
    // Passing in the anonymousId through traits is an undocumented feature described here: https://github.com/segmentio/analytics-react-native/issues/125#issuecomment-620311000
    // This doesn't actually work to override the anonymousId, but is still useful to identify the userId to _an_ anonymousId
    // The anonymousId going forward on the server side would be linked to this userId
    analytics.identify(userId, { ...traits, anonymousId })
  }
}

/**
 * Replace non-word and non-dash chars with underscores for HotJar event names.
 *
 * @param eventName TrackingEvent name with spaces
 */
export function underscoreForHotJar(eventName: string) {
  return eventName.replace(/[^\w\-]/gi, '_')
}
