'use client'
import { Icon } from '@components/primitives/Icon'
import { Text } from '@components/primitives/Text'
import { faArrowAltToTop } from '@fortawesome/pro-solid-svg-icons/faArrowAltToTop'
import type { TextColor } from '@lib/colors'

export const BackToTopText = ({
  color = 'text-white',
  size = 'medium',
}: {
  color?: TextColor
  size?: 'small' | 'medium'
}) => {
  return (
    <div
      className='flex cursor-pointer items-center justify-center'
      onClick={() => window.scrollTo({ behavior: 'smooth', top: 0 })}
    >
      <Icon
        className={`mr-2 ${color}`}
        icon={faArrowAltToTop}
        size={size === 'medium' ? '1x' : 'sm'}
      />
      <Text
        className={color}
        styleName={size === 'medium' ? 'p' : 'p-small'}
        value='Back to top'
      />
    </div>
  )
}
