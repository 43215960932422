import type { ColorHexCode } from '@lib/colors'
import { colors } from '@lib/colors'
import type { LogoSize } from '..'

export default function PowerLogoName({
  fillColor = colors.black,
  opacity = 1,
  size = 'Medium',
}: {
  fillColor?: ColorHexCode
  opacity?: number
  size?: LogoSize
}) {
  function getViewBoxSizeFromLogoSize(size: LogoSize) {
    switch (size) {
      case 'Giant':
      case 'Large':
        return '0 0 107 28'
      case 'Medium':
        return '0 0 96 38'
      case 'Small':
        return '0 0 100 38'
      default:
        return '0 0 96 25'
    }
  }
  function getWidthFromLogoSize(size: LogoSize) {
    switch (size) {
      case 'Giant':
      case 'Large':
        return '96'
      case 'Medium':
        return '60'
      case 'Small':
        return '50'
      default:
        return '96'
    }
  }
  return (
    <svg
      fill='none'
      height='25'
      viewBox={getViewBoxSizeFromLogoSize(size)}
      width={getWidthFromLogoSize(size)}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M0.699951 0H11.036C11.818 0 12.498 0.0453338 13.076 0.136001C14.504 0.351333 15.6883 0.827334 16.629 1.564C17.5696 2.28933 18.2666 3.20733 18.72 4.318C19.1733 5.42867 19.4 6.68667 19.4 8.092C19.4 9.49733 19.1676 10.7553 18.7029 11.866C18.2496 12.9767 17.5526 13.9003 16.612 14.637C15.6713 15.3623 14.4926 15.8327 13.076 16.048C12.498 16.1387 11.818 16.184 11.036 16.184H5.32395V24.48H0.699951V0ZM10.832 11.866C11.478 11.866 11.9993 11.8093 12.396 11.696C12.974 11.5373 13.4386 11.2653 13.79 10.88C14.1413 10.4833 14.385 10.047 14.521 9.571C14.6683 9.08367 14.742 8.59067 14.742 8.092C14.742 7.59333 14.6683 7.106 14.521 6.63C14.385 6.14267 14.1413 5.70633 13.79 5.321C13.4386 4.92433 12.974 4.64667 12.396 4.488C11.9993 4.37467 11.478 4.318 10.832 4.318H5.32395V11.866H10.832Z'
        fill={fillColor}
        opacity={opacity}
      />
      <path
        d='M29.3832 24.99C27.5585 24.99 25.9435 24.582 24.5382 23.766C23.1329 22.9387 22.0392 21.794 21.2572 20.332C20.4865 18.8587 20.1012 17.1813 20.1012 15.3C20.1012 13.4073 20.4922 11.73 21.2742 10.268C22.0675 8.79467 23.1669 7.65 24.5722 6.834C25.9889 6.018 27.5925 5.61 29.3832 5.61C31.2079 5.61 32.8229 6.02367 34.2282 6.851C35.6449 7.667 36.7442 8.81167 37.5262 10.285C38.3082 11.7583 38.6992 13.43 38.6992 15.3C38.6992 17.1813 38.3025 18.8587 37.5092 20.332C36.7272 21.794 35.6279 22.9387 34.2112 23.766C32.8059 24.582 31.1965 24.99 29.3832 24.99ZM29.3832 20.672C30.3465 20.672 31.1569 20.451 31.8142 20.009C32.4829 19.5557 32.9815 18.9267 33.3102 18.122C33.6389 17.306 33.8032 16.3653 33.8032 15.3C33.8032 13.634 33.4235 12.325 32.6642 11.373C31.9049 10.4097 30.8112 9.928 29.3832 9.928C28.3972 9.928 27.5755 10.149 26.9182 10.591C26.2722 11.033 25.7905 11.6563 25.4732 12.461C25.1559 13.2657 24.9972 14.212 24.9972 15.3C24.9972 16.4107 25.1615 17.3683 25.4902 18.173C25.8302 18.9777 26.3232 19.5953 26.9692 20.026C27.6265 20.4567 28.4312 20.672 29.3832 20.672Z'
        fill={fillColor}
        opacity={opacity}
      />
      <path
        d='M38.7135 6.086L43.2355 6.12L46.5675 17.068L49.9505 6.12H53.7925L57.1755 17.068L60.5075 6.12H65.0295L59.4195 24.48H55.8835L51.8715 12.512L47.8595 24.48H44.3235L38.7135 6.086Z'
        fill={fillColor}
        opacity={opacity}
      />
      <path
        d='M74.6149 24.99C72.7562 24.99 71.1016 24.5877 69.6509 23.783C68.2116 22.9783 67.0839 21.8563 66.2679 20.417C65.4632 18.9777 65.0609 17.3513 65.0609 15.538C65.0609 13.566 65.4519 11.832 66.2339 10.336C67.0272 8.82867 68.1266 7.667 69.5319 6.851C70.9372 6.02367 72.5409 5.61 74.3429 5.61C76.2469 5.61 77.8789 6.06333 79.2389 6.97C80.5989 7.86533 81.5962 9.146 82.2309 10.812C82.8769 12.4667 83.1092 14.3933 82.9279 16.592H78.3549V14.892C78.3549 13.634 78.2246 12.6253 77.9639 11.866C77.7032 11.0953 77.2952 10.5287 76.7399 10.166C76.1846 9.80333 75.4536 9.622 74.5469 9.622C73.5042 9.622 72.6429 9.83733 71.9629 10.268C71.2829 10.6873 70.7786 11.3163 70.4499 12.155C70.1212 12.9937 69.9569 14.042 69.9569 15.3C69.9569 16.4447 70.1212 17.4193 70.4499 18.224C70.7786 19.0173 71.2716 19.6237 71.9289 20.043C72.5862 20.4623 73.3909 20.672 74.3429 20.672C75.2382 20.672 76.0089 20.4737 76.6549 20.077C77.3122 19.6803 77.8109 19.108 78.1509 18.36H82.9279C82.9279 19.5 82.1091 21.3212 80.8539 22.542C80.0266 23.3353 79.0746 23.9417 77.9979 24.361C76.9212 24.7803 75.7936 24.99 74.6149 24.99ZM68.5289 13.158H80.7009V16.592H68.5289V13.158Z'
        fill={fillColor}
        opacity={opacity}
      />
      <path
        d='M85.4064 6.12H90V10.608V8.279C90 8.279 90 8.279 90.0134 8.279C90.4214 7.75767 90.9031 7.33267 91.4584 7.004C91.8891 6.732 92.3651 6.52233 92.8864 6.375C93.4078 6.21633 93.9461 6.12 94.5014 6.086C95.0568 6.04067 95.6178 6.052 96.1844 6.12V10.812C94.7129 10.9422 93.5845 10.993 92.5804 11.744C91.5764 12.495 91.2994 12.703 90.5764 13.995C90.2236 14.6256 90.0644 15.3227 90.0644 16.096V24.48H85.4064V6.12Z'
        fill={fillColor}
        opacity={opacity}
      />
    </svg>
  )
}
