import { classMerge } from '@components/utilities/classMerge'
import type { ColorHexCode } from '@lib/colors'
import { colors } from '@lib/colors'

import PowerLogoIcon from './PowerLogoIcon'
import PowerLogoName from './PowerLogoName'

export type LogoSize = 'Small' | 'Medium' | 'Large' | 'Giant'

function PowerLogo({
  className,
  iconClassName,
  iconColor = colors.black,
  iconOpacity = 1,
  nameClassName,
  nameColor = colors.black,
  nameOpacity = 1,
  size = 'Medium',
}: {
  className?: string
  iconClassName?: string
  iconColor?: ColorHexCode
  iconOpacity?: number
  nameClassName?: string
  nameColor?: ColorHexCode
  nameOpacity?: number
  size?: LogoSize
}) {
  return (
    <div className={classMerge('flex justify-center', className)}>
      <span className={classMerge('pr-1', iconClassName)}>
        <PowerLogoIcon
          fillColor={iconColor}
          opacity={iconOpacity}
          size={size}
        />
      </span>
      <span className={classMerge('hidden sm:inline', nameClassName)}>
        <PowerLogoName
          fillColor={nameColor}
          opacity={nameOpacity}
          size={size}
        />
      </span>
    </div>
  )
}

PowerLogo.Icon = PowerLogoIcon
PowerLogo.Name = PowerLogoName

export default PowerLogo
