import type { UrlParamCookie } from '@lib/browsers/cookies/extractUtmParamsFromCookie'
import type { StringDictionary } from '@lib/types/dictionary'
import { removeUndefinedKeys } from '@lib/utilities/object/removeUndefinedKeys'
import type { Url } from '.'
import Routes, { RoutePrefix } from '.'
import getQueryParamsToAppendToLink from './helpers/getQueryParamsToAppendToLink'
import urlWithQueryParams from './helpers/urlWithQueryParams'

type DynamicPrefix =
  | 'aboutAuthor'
  | 'center'
  | 'chart'
  | 'doctors'
  | 'go'
  | 'guides'
  | 'og'
  | 'partnerTrials'
  | 'redirectedDomain'
  | 'redirectedPage'
  | 'specialists'
  | 'site'
  | 'topic'
  | 'topicPaginated'
  | 'treatmentTopic'
  | 'trial'
  | 'trialNaked'
  | 'trialDirectoryPage'
  | 'trialSearch'
  | 'trialSearchLocation'

const DynamicRoutes: Record<DynamicPrefix, (...args: any[]) => Url> = {
  // /about/:slug
  aboutAuthor: (authorSlug) =>
    `${RoutePrefix.ABOUT}/${authorSlug.toLowerCase()}`,
  // /center/:slug
  center: (slug: string) => `${RoutePrefix.CENTER}/${slug.toLowerCase()}`,
  // /trial/:slug/charts/:chartType
  chart: (slug: string, chartType: string) =>
    `${RoutePrefix.TRIAL}/${slug.toLowerCase()}/charts/${chartType}`,
  // /go/:shortcode/:parts
  doctors: (slug: string) => `${RoutePrefix.DOCTOR}/${slug.toLowerCase()}`,
  go: (shortcode: string, ...parts: string[]) =>
    [RoutePrefix.SHORTCODE, shortcode, ...parts].join('/').replace(/\/+/g, '/'),
  // /guides/:slug
  guides: (slug: string) => `${RoutePrefix.GUIDES}/${slug}`,
  // /og/:shortcode/:parts
  og: (shortcode: string, ...parts: string[]) =>
    [RoutePrefix.OPENGRAPH_SHORTCODE, shortcode, ...parts].join('/'),
  // /partner-trials/:slug
  partnerTrials: (slug: string) =>
    `${RoutePrefix.PARTNER_TRIALS}/${slug.toLowerCase()}`,
  // /r/:slug
  redirectedDomain: (slug: string) =>
    `${RoutePrefix.REDIRECTS}/${slug.toLowerCase()}`,
  // /r/:domain/:slug
  redirectedPage: (domain: string, slug: string) =>
    `${RoutePrefix.REDIRECTS}/${domain}/${slug.toLowerCase()}`,
  // /site/:slug
  site: (slug: string) => `${RoutePrefix.TRIAL_SITE}/${slug.toLowerCase()}`,
  // /clinical-trials/:slug
  specialists: (slug: string) => `${RoutePrefix.SPECIALISTS}/${slug}`,
  topic: (slug: string) =>
    `${RoutePrefix.DIRECTORY_TOPIC}/${slug.toLowerCase()}`,
  // /clinical-trials/:slug/:pageNumber
  topicPaginated: (slug: string, pageNumber: number) =>
    pageNumber < 1
      ? DynamicRoutes.topic(slug.toLowerCase())
      : `${DynamicRoutes.topic(slug.toLowerCase())}/${pageNumber}`,
  // /t/:slug
  treatmentTopic: (slug: string) =>
    `${RoutePrefix.TREATMENTS}/${slug.toLowerCase()}`,
  // /trial/:slug?abc=def
  trial: (slug: string, urlParams?: UrlParamCookie) =>
    urlWithQueryParams(
      `${RoutePrefix.TRIAL}/${slug.toLowerCase()}`,
      getQueryParamsToAppendToLink(urlParams),
    ),

  // /trial-directory/:pageNumber
  trialDirectoryPage: (pageNumber: string) =>
    parseInt(pageNumber.toString(), 10) < 1
      ? Routes.trialDirectoryRoot
      : `${RoutePrefix.TRIAL_DIRECTORY}/${pageNumber}`,

  // /trial/:slug
  trialNaked: (slug: string) => `${RoutePrefix.TRIAL}/${slug.toLowerCase()}`,
  // /clinical-trials/search
  trialSearch: (query?: StringDictionary) =>
    urlWithQueryParams(
      `${RoutePrefix.DIRECTORY_TOPIC}/search`,
      query ? removeUndefinedKeys(query) : {},
    ).toString(),
  trialSearchLocation: (location: string, lat: number, lng: number) =>
    `${DynamicRoutes.trialSearch()}?lat=${lat}&lng=${lng}&currentLocation=${encodeURIComponent(
      location,
    )}`,
}

export default DynamicRoutes
