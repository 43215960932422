import { Manrope } from 'next/font/google'
import type { ReactNode } from 'react'

export const manrope = Manrope({
  style: ['normal'],
  subsets: ['latin'],
  variable: '--font-manrope',
  weight: ['500', '600', '700'],
})

export const FontWrapper = ({ children }: { children: ReactNode }) => (
  <main className={`${manrope.variable} font-Manrope`}>{children}</main>
)
