import { usePrevious } from '@components/hooks/usePrevious'
import extractOriginalReferrer from '@lib/browsers/cookies/extractOriginalReferrer'
import { URL_PARAM_STORAGE_KEY } from '@lib/browsers/cookies/extractTrackingOptionsFromUtmParams'
import { isProcessClientSide } from '@lib/config/runtime'
import { trackPageview } from '@lib/tracking'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { useCookies } from 'react-cookie'
import MetricsAndTrackingScripts from './MetricsAndTrackingScripts'
import type { MetricsAndTrackingProps } from './types'

export default function MetricsAndTracking({
  loadHotJar,
  loadMaze = false,
  loadRedditPixel = true,
}: MetricsAndTrackingProps) {
  const [cookies, setCookie] = useCookies([URL_PARAM_STORAGE_KEY])
  const router = useRouter()
  const prevRoute = usePrevious(router.route)
  const doc = isProcessClientSide() ? document : null

  useEffect(() => {
    const oldCookie = cookies.urlParams ? cookies.urlParams : {}
    const newCookie = {
      ...oldCookie,
      ...router.query,
      ...extractOriginalReferrer(),
    }
    setCookie(URL_PARAM_STORAGE_KEY, newCookie, {
      path: '/', // Allow all pages to read the cookie
      sameSite: 'lax',
      secure: true,
    })
    // Do not include setCookie or else we'll get infinite re-renders
  }, [router.query, doc])

  // Subscribe to router events to trigger page views, even if we use a shallow push state
  // See https://nextjs.org/docs/api-reference/next/router#routerevents
  useEffect(() => {
    const handlePageView = () => {
      if (prevRoute !== router.route) {
        // if it is an actual route change, track the page view
        trackPageview(router)
      }
    }

    router.events.on('routeChangeComplete', handlePageView)

    return () => {
      router.events.off('routeChangeComplete', handlePageView)
    }
  }, [router])

  // Add a special case that triggers once on initial app load since the router change
  // tracking only occurs upon path transitions (i.e. navigating between pages)
  useEffect(() => {
    trackPageview(router)
  }, [])

  return (
    <MetricsAndTrackingScripts
      loadHotJar={loadHotJar}
      loadMaze={loadMaze}
      loadRedditPixel={loadRedditPixel}
    />
  )
}
