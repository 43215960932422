import Head from 'next/head'

interface FaviconProps {
  inSSP: boolean
}

export function getFaviconPath({
  env = process.env.CURRENT_ENV ?? process.env.NEXT_PUBLIC_CURRENT_ENV,
  inSSP,
}: {
  env?: string
  inSSP: boolean
}) {
  switch (env) {
    case 'production':
      if (inSSP) {
        return '/ssp_favicon.png'
      } else {
        return '/favicon.png'
      }
    case 'preview':
      return '/preview_favicon.png'
    case 'development':
      return '/development_favicon.png'
    default:
      return '/favicon.png'
  }
}

export default function Favicon({ inSSP }: FaviconProps) {
  const faviconPath = getFaviconPath({ inSSP })
  return (
    <Head>
      <link href={faviconPath} rel='icon' />
    </Head>
  )
}
