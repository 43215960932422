import type { NextRouter } from 'next/router'

export const getPageTrackPropsFromRouter = (router: NextRouter) => {
  const urlParams = new URLSearchParams(window?.location?.search)
  const searchParamsObject = Object.fromEntries(urlParams.entries())
  const nextQuery = new URLSearchParams(router.query as Record<string, string>)
  const nextQueryObject = Object.fromEntries(nextQuery.entries())
  const queryParams = { ...searchParamsObject, ...nextQueryObject }

  return {
    path: window?.location?.pathname,
    queryParams,
    route: router.pathname,
  }
}
