import Button from '@components/primitives/Button'
import { Icon } from '@components/primitives/Icon'
import Modal from '@components/primitives/Modal/index.client'
import { Text } from '@components/primitives/Text'
import { faClock } from '@fortawesome/pro-solid-svg-icons'
import signOutUser from '@lib/utilities/signOutUser'
import pluralize from '@lib/utilities/text/pluralize'
import { ONE_MINUTE_IN_MS } from '@lib/utilities/time'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'

const AUTO_SIGN_OUT_DELAY = ONE_MINUTE_IN_MS

const INITIAL_SECONDS_REMAINING = Math.floor(AUTO_SIGN_OUT_DELAY / 1000)

/**
 * A modal which starts a timeout to automatically sign the user out.
 * This is used to prevent users from accidentally leaving their session open.
 * Can be cancelled by submitting the form or closing the modal.
 */
export default function UserInactivityModal({
  onClose,
  visible,
}: {
  onClose: () => void
  visible: boolean
}) {
  const router = useRouter()
  const [signedOut, setSignedOut] = useState(false)
  const [secondsRemaining, setSecondsRemaining] = useState(
    INITIAL_SECONDS_REMAINING,
  )

  let autoSignOutTimeout: NodeJS.Timeout | undefined
  let secondsRemainingTimer: NodeJS.Timer | undefined

  useEffect(() => {
    if (!visible) {
      return
    }

    if (!autoSignOutTimeout) {
      autoSignOutTimeout = setTimeout(() => {
        signOutUser()
        setSignedOut(true)
      }, AUTO_SIGN_OUT_DELAY)
    }

    return () => {
      clearTimeout(autoSignOutTimeout)
    }
  }, [visible])

  useEffect(() => {
    if (!visible) {
      return
    }

    setSecondsRemaining(INITIAL_SECONDS_REMAINING)

    if (!secondsRemainingTimer) {
      secondsRemainingTimer = setInterval(decrementTimer, 1000)
    }

    return () => {
      clearTimeout(secondsRemainingTimer)
    }
  }, [visible])

  function decrementTimer() {
    setSecondsRemaining((prevSecondsRemaining) =>
      Math.max(prevSecondsRemaining - 1, 0),
    )
  }

  function handleClose() {
    clearTimeout(autoSignOutTimeout)
    onClose()
  }

  function handleRefresh() {
    handleClose()
    router.reload()
  }

  return (
    <Modal
      bodyClassName='flex flex-col items-center gap-8 pt-8'
      onClose={signedOut ? handleRefresh : handleClose}
      showHeader={false}
      visible={visible}
    >
      <div className='flex items-center gap-2'>
        <Icon className='text-blue500' icon={faClock} size='lg' />
        <Text
          className='text-center'
          element='h4'
          styleName='h4'
          value={
            signedOut
              ? 'Your session has expired'
              : 'Your session is about to expire'
          }
        />
      </div>
      <Text
        className='text-neutral600'
        styleName='p-small'
        value={
          signedOut
            ? 'Please refresh the page to continue'
            : `We'll log you out in ${pluralize('second', secondsRemaining, {
                includeCount: true,
              })} unless you choose to continue`
        }
      />
      <Button
        className='rounded-full'
        color={signedOut ? 'darkGrey' : 'black'}
        onClick={signedOut ? handleRefresh : handleClose}
        value={
          <Text
            className={signedOut ? 'text-black' : 'text-white'}
            value={signedOut ? 'Refresh' : 'Continue'}
          />
        }
      />
    </Modal>
  )
}
