import LocalStorageItem from '@lib/browsers/localStorage/LocalStorageItem'
import type { GeolocationWithLocationName } from '@lib/types/Geolocation'
import { useRouter } from 'next/router'
import type { ReactElement } from 'react'
import { createContext, useCallback, useEffect, useState } from 'react'
import getLatLngFromIp from './getLatLngFromIp'
import { getLatLngFromWindowUrl } from './getLatLngFromWindowUrl'

/**
 * Provide a GeolocationContext to be able to share lat / lng and locationName from child components.
 * Import this in a `useContext`: https://reactjs.org/docs/hooks-reference.html#usecontext
 */
export const GeolocationContext = createContext<GeolocationWithLocationName>({
  latitude: null,
  locationName: undefined,
  longitude: null,
})

type GeolocationProviderProps = {
  children: ReactElement | ReactElement[]
}
function GeolocationProvider({ children }: GeolocationProviderProps) {
  const router = useRouter()
  const geoStorage = new LocalStorageItem('geolocation')
  const geoInLocalStorage = (geoStorage.getItem() ??
    {}) as GeolocationWithLocationName

  const [coords, setCoords] =
    useState<GeolocationWithLocationName>(geoInLocalStorage)

  const setCoordinates = (coordinates: GeolocationWithLocationName) => {
    setCoords(coordinates)
    geoStorage.setItem(coordinates)
  }

  const determineUserLocation = useCallback(async () => {
    // If we have it in the query params, thats the source of truth, some input form has modified it
    const { queryLatitude, queryLocationName, queryLongitude } =
      getLatLngFromWindowUrl()
    if (queryLatitude && queryLongitude) {
      return setCoordinates({
        latitude: parseFloat(queryLatitude),
        locationName: queryLocationName ?? undefined,
        longitude: parseFloat(queryLongitude),
      })
    }
    // Otherwise, if it isn't set grab the userLocation from the IP and stuff it into our context state
    if (!coords.latitude && !coords.longitude) {
      const urlParams = new URLSearchParams(window.location.search)
      const queryIp = urlParams.get('ip')
      const ip = queryIp ? queryIp : undefined
      const { latitude, locationName, longitude } = await getLatLngFromIp(ip)
      if (latitude && longitude) {
        return setCoordinates({ latitude, locationName, longitude })
      }
    }
  }, [setCoords, coords.latitude, coords.longitude])

  /**
   * This effect is triggered if the url is changed (like when a zipcode input form triggers a query param change)
   */
  useEffect(() => {
    determineUserLocation()
  }, [router.query, determineUserLocation])

  return (
    <GeolocationContext.Provider value={coords}>
      {children}
    </GeolocationContext.Provider>
  )
}

export default GeolocationProvider
